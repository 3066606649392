import API from '@api/corporate.api';
import pagination from '../pagination';

function initialState() {
  return {
    ...pagination.state,
    propertyId: null,
    videos: [],
    filter: ''
  };
}

const getters = {
  ...pagination.getters,
  videos: (state) => state.videos,
  propertyId: (state) => state.propertyId,
  filter: (state) => state.filter
};

const mutations = {
  ...pagination.mutations,
  SET_PROPERTY_ID(state, propertyId) {
    state.propertyId = propertyId;
  },
  SET_PROPERTY_VIDEOS(state, data) {
    state.videos = data;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  ADD_PROPERTY_VIDEOS(state, data) {
    state.videos.push(...data);
  },
  RESET(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setPropertyId({ commit }, propertyId) {
    commit('SET_PROPERTY_ID', propertyId);
  },
  async apiGetPropertyVideos({ state }, params) {
    const { data } = await API.getPropertyVideos(
      state.propertyId,
      {
        perPage: state.perPage,
        sort: 'createdAt',
        sortBy: 'desc',
        ...params
      }
    );

    return data;
  },
  async getPropertyVideosList({ state, commit, dispatch }) {
    commit('SET_LOADING', true);

    const data = await dispatch('apiGetPropertyVideos', {
      page: state.page,
      ...state.filter
    });

    commit('SET_LOADING', false);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_PROPERTY_VIDEOS', data.records);
  },
  async applyFilter({ state, commit, dispatch }, filter) {
    commit('SET_LOADING', true);

    const data = await dispatch('apiGetPropertyVideos', {
      page: 1,
      ...filter
    });

    commit('SET_LOADING', false);
    commit('SET_PAGE', 1);
    commit('SET_FILTER', filter);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('SET_PROPERTY_VIDEOS', data.records);
  },
  async fetchMore({ state, commit, dispatch }) {
    commit('SET_FETCHING_MORE', true);

    const nextPage = state.page + 1;

    const data = await dispatch('apiGetPropertyVideos', {
      page: nextPage,
      ...state.filter
    });

    commit('SET_FETCHING_MORE', false);
    commit('SET_PAGE', nextPage);
    commit('SET_SHOW_MORE', state.page < data.pagination.pages);
    commit('ADD_PROPERTY_VIDEOS', data.records);
  },
  resetState({ commit }) {
    commit('RESET');
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
};
