import apiClient from './apiClient';

export default {
  searchRecipients: (params) => apiClient.get('/pre-recorded-video/tracking/search-recipient', { params }),
  getVideoStatistics: (videoId, params) => apiClient.get(`/pre-recorded-video/${videoId}/breakdown`, { params }),
  removeTracking: (params) => apiClient.put(`/pre-recorded-video/tracking/${params.id}/delete`, { ...params }, { silentErrorNotification: true }),
  updateRecipient: (trackingId, params) => apiClient.put(`/pre-recorded-video/tracking/${trackingId}`, { ...params }),
  updateTracking: (id, params) => apiClient.put(`/pre-recorded-video/tracking/${id}`, { ...params }),
  saveRecipients: (params) => apiClient.post('/pre-recorded-video/tracking/recipients', { ...params }),
  restoreDeletedLink: ({ linkId, ...rest }) => apiClient.put(`/pre-recorded-video/tracking/${linkId}/restore`, { ...rest }),
  // video links types:
  // [all, qr_code, prospect, yardi, entrata, integration, embed_code, social_media]
  getShareLinks: (videoId, params) => apiClient.post(`pre-recorded-video/${videoId}/links`, { ...params }),
  // playlist links types:
  // [all, qr_code, prospect, yardi, entrata, social_media]
  getPlaylistShareLinks: (playlistId, params) => apiClient.post(`/pre-recorded-video/playlist/${playlistId}/links`, { ...params }),
  getVideoGenericLink: (videoId) => apiClient.post(`/pre-recorded-video/${videoId}/generic-links`),
  saveSocialMediaTracking: (params) => apiClient.post('/pre-recorded-video/tracking/social-media', { ...params }),
  createQrCodeTracking: (params) => apiClient.post('/pre-recorded-video/tracking/qr_code', { ...params }),
  trackCopy: (trackingId, params) => apiClient.put(
    `/pre-recorded-video/tracking/${trackingId}/copy`,
    { ...params },
    { silentErrorNotification: true }
  ),
  trackCopyGif: (trackingId) => apiClient.put(`/pre-recorded-video/tracking/${trackingId}/copy-gif`)
};
