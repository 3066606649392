import apiClient from './apiClient';

export default {
  getProperties: (params) => apiClient.get('/corporate/properties', { params }),
  getPropertiesByIds: (params) => apiClient.post('/corporate/properties/get-by-ids', params),
  getProperty: (propertyId) => apiClient.get(`/corporate/properties/${propertyId}`),
  getPropertyAgents: (propertyId) => apiClient.get(`/corporate/properties/${propertyId}/users`),
  getPropertyInfo: (propertyId) => apiClient.get(`/corporate/properties/${propertyId}/info`),
  getPropertyPlaylists: (propertyId, params) => apiClient.get(`/corporate/properties/${propertyId}/playlists`, { params }),
  getPropertyVideos: (propertyId, params) => apiClient.get(`/corporate/properties/${propertyId}/videos`, { params }),
  getPropertyDeletedVideos: (propertyId, params) => apiClient.get(`/corporate/properties/${propertyId}/deleted-videos`, { params }),
  getPropertyFolders: (propertyId, params) => apiClient.get(`/corporate/properties/${propertyId}/folders`, { params }),
  getPropertyFolderContent: (propertyId, folderId, params) => apiClient.get(`/corporate/properties/${propertyId}/folders/${folderId}`, { params }),
  getPropertyMonthlyGenericViews: (propertyId) => apiClient.get(`/corporate/properties/${propertyId}/monthly-generic-views`),
  getPropertyVideoTypes: (propertyId) => apiClient.get(`/corporate/properties/${propertyId}/video-types`),
  getPropertyVideoTypeOptions: (videoTypeId, params) => apiClient.get(
    `/corporate/properties/${videoTypeId}/video-type-options`,
    { params }
  ),

  getPropertyLiveTours: ({ propertyId, ...params }) => apiClient.get(`/corporate/properties/${propertyId}/tours`, { params }),
  getPropertyLiveTourTotals: ({ propertyId, ...params }) => apiClient.get(`/corporate/properties/${propertyId}/tours/totals`, { params }),
  getPropertyLiveSessions: ({ propertyId, ...params }) => apiClient.get(`/corporate/properties/${propertyId}/sessions`, { params }),
  getPropertyLiveSessionTotals: ({ propertyId, ...params }) => apiClient.get(`/corporate/properties/${propertyId}/sessions/totals`, { params }),
  moveVideo: (videoId, params) => apiClient.post(`/corporate/properties/videos/${videoId}/move`, { ...params }),
  copyVideo: (videoId, params) => apiClient.post(`/corporate/properties/videos/${videoId}/copy`, { ...params }),

  getProspects: (params) => apiClient.get('/corporate/prospects', { params }),
  getProspect: (prospectId) => apiClient.get(`/corporate/prospects/${prospectId}`),
  getProspectSummary: (prospectId) => apiClient.get(`/corporate/prospects/${prospectId}/summary`),
  getProspectTimeline: (prospectId, params) => apiClient.get(`/corporate/prospects/${prospectId}/timeline`, { params }),

  getPropertyIntegrations: (params) => apiClient.get('/corp_properties/integrations', { params }),
  getCorpUsersReport: (params) => apiClient.get('/corporate/users', { params }),
  generateCorpUsersExportData: (params) => apiClient.get('/corporate/users/export', { params }),

  getPropertyPins: (propertyId) => apiClient.get(`/corporate/properties/${propertyId}/pins`),
  putPropertyPins: (propertyId, params) => apiClient.put(`/corporate/properties/${propertyId}/pins`, { ...params }),
  updatePropertyPin: (propertyId, pinId, params) => apiClient.patch(`/corporate/properties/${propertyId}/pins/${pinId}`, { ...params }),
  deletePropertyPin: (propertyId, pinId) => apiClient.delete(`/corporate/properties/${propertyId}/pins/${pinId}`),

  getQuickStats: (params) => apiClient.get('/corporate/quick-stats', { params }),
  getTopPropertiesByVideos: (params) => apiClient.get('/corporate/top-properties-by-videos', { params }),
  getLiveEventsList: ({ propertyId, ...rest }) => apiClient.get(`/corporate/properties/${propertyId}/live/list`, { params: { ...rest } })
};
