export const s3UploadPaths = {
  resizedBucketPath: 'https://realync-resize-image.s3-us-west-2.amazonaws.com/',
  videoAndClipImages: 'https://video-and-clip-images.s3-us-west-2.amazonaws.com/',
  s3ResizeBucket: 'https://s3-us-west-2.amazonaws.com/realync-resize-image/',
  originalPath: 'https://realync-original-img.s3.us-west-2.amazonaws.com/',
  originalDevPath: 'https://s3.us-west-2.amazonaws.com/realync-original-img-dev/',
  amazonS3Path: 'https://s3-us-west-2.amazonaws.com/'
};

export const s3Buckets = {
  videoPreview: 'video-and-clip-images',
  attachments: 'realync-attachments',
  original: 'realync-original-img',
  originalDev: 'realync-original-img-dev',
  videoUploadedBucketStage: 'transcoded-videos-stg',
  videoUploadedBucketProd: 'transcoded-videos-s',
  videoUploadBucketStage: 'transcoding-videos-stg',
  videoUploadBucketProd: 'transcoding-videos-s',
  clipBucketProd: 'published-video-clips',
  clipBucketStage: 'published-video-clips-stage',
  clipUploadBucketStage: 'published-video-clips-mkv-stg',
  clipUploadBucketProd: 'published-video-clips-mkv-production',
  photoGalleryStage: 'realync-capture-images-stage',
  photoGalleryProd: 'realync-capture-images-prod'
};

export const bucketFolders = {
  customLogo: 'custom_logo/',
  videos: 'videos/',
  openingSlide: 'opening_slide/',
  images: 'images/',
  videoClips: 'published-video-clips/',
  playlist: 'playlist/',
  root: ''
};

export const uploadReferenceNames = {
  videoPreview: '--host-video-',
  reusedClip: '--reusedClip_',
  attachments: '--attachments-',
  customLogo: '--custom_logo_',
  openingSlide: '--opening_slide_',
  user: '--user-',
  playlistThumbnail: '--playlist-thumbnail_'
};

/**
 * Helps to determine which image should be used for branding preview
 * Usage: brandingPreviewPlaceholders['video'||'videoLink'||'playlist'||'playlistLink']
 */
export const brandingPreviewPlaceholders = {
  video: require('@images/branding/branding-video-placeholder.jpg'),
  videoLink: require('@images/branding/branding-video-placeholder.jpg'),
  playlist: require('@images/branding/branding-playlist-placeholder.jpg'),
  playlistLink: require('@images/branding/branding-playlist-placeholder.jpg')
};

/**
 * Links to info pages about DataTypes for exporting
 */
export const dataExportTipLinks = {
  liveTours: 'https://intercom.help/realync/en/articles/5464316-live-tour-stats',
  liveSessions: 'https://intercom.help/realync/en/articles/5464885-live-session-stats',
  links: 'https://intercom.help/realync/en/articles/5464887-links',
  videos: 'https://intercom.help/realync/en/articles/5464888-videos',
  prospects: 'https://intercom.help/realync/en/articles/5464891-prospects',
  videoViews: 'https://intercom.help/realync/en/articles/5464893-video-views',
  linkViews: 'https://intercom.help/realync/en/articles/5464895-link-views',
  lTLSAttendees: 'https://intercom.help/realync/en/articles/5464899-live-tour-live-session-attendees'
};

/**
 * Realync default branding color. Used all around the application
 */
export const REALYNC_DEFAULT_COLOR = '#13a6ca';

/**
 * Realync default address. Used when no other possible property address is not available
 */
export const DEFAULT_ADDRESS = 'Chicago, Illinois, USA';

/**
 * Defaults for GoogleMaps
 */
export const GOOGLE_MAP_API_KEY = 'AIzaSyAfMmEaDmcTey-krQm3SjAhTX-mRruKueU';

export const pinTemplate = `
  <svg width="24px" height="32px" viewBox="0 0 24 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Map_pin_5" transform="translate(-72.000000, -244.000000)" fill-rule="nonzero">
            <g id="Group" transform="translate(72.000000, 244.000000)">
                <path d="M12,-1.19015908e-13 C5.38316426,-1.19015908e-13 0,5.198875 0,11.589125 C0,19.519625 10.7388244,31.1620625 11.1960394,31.6538125 C11.6254914,32.11575 12.3752852,32.1149375 12.8039606,31.6538125 C13.2611756,31.1620625 24,19.519625 24,11.589125 C24,5.198875 18.616771,-1.19015908e-13 12,-1.19015908e-13 Z M12.0000322,17.0001286 C8.69156354,17.0001286 6,14.3085008 6,11.0000322 C6,7.69156354 8.69162785,5 12.0000322,5 C15.3084365,5 18,7.69162785 18,11.0000965 C18,14.3085651 15.3084365,17.0001286 12.0000322,17.0001286 Z" id="Shape" fill="#089FD3"></path>
                <circle id="Oval" fill="#F4D13B" cx="12" cy="11" r="4"></circle>
            </g>
        </g>
    </g>
  </svg>
`;

export const defaultPinTemplate = `
  <svg width="32px" height="42px" viewBox="0 0 32 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Map_pin_5" transform="translate(-21.000000, -239.000000)" fill-rule="nonzero">
            <g id="Group-2" transform="translate(21.000000, 239.000000)">
                <path d="M15.75,-1.42108547e-14 C7.0654031,-1.42108547e-14 0,6.82352344 0,15.2107266 C0,25.6195078 14.0947071,40.900207 14.6948017,41.5456289 C15.2584575,42.1519219 16.2425618,42.1508555 16.8051983,41.5456289 C17.4052929,40.900207 31.5,25.6195078 31.5,15.2107266 C31.5,6.82352344 24.434512,-1.42108547e-14 15.75,-1.42108547e-14 Z M15.7500422,22.3126688 C11.4076771,22.3126688 7.875,18.7799073 7.875,14.4375422 C7.875,10.0951771 11.4077616,6.5625 15.7500422,6.5625 C20.0923229,6.5625 23.625,10.0952616 23.625,14.4376266 C23.625,18.7799917 20.0923229,22.3126688 15.7500422,22.3126688 Z" id="Shape" fill="#EA4335"></path>
                <circle id="Oval" fill="#1AA1D1" cx="15.75" cy="14.4375" r="5.25"></circle>
            </g>
        </g>
    </g>
  </svg>
`;

/**
 * Default data for Analytics, FusionCharts, FusionTime
 */
export const FUSION_CHARTS_LICENSE_KEY = '7f1E1hwF-7C11C2C7D3B2B2A2F2D1C6D7E6F4xkcD2I3trxfsC1C1jI-7oB1E6B1B3H3E2A14A19B7C2C6C3D2G4F4D3I-7acA5C5E3sA2A1A1feA-16E2D6G2zB-7mC2D6C8nvrG4E1C3B3C7D2C6B1D3B4H3uA-21D-16E4F4NB2qH-8jA3C3C1B-11jD1e1H3H4fd1qB5D1B3C1C1C1A1D9C1C2D1C4F3C2i==';

export const analyticsDataTypes = {
  LTLSCompleted: 'LTLSCompleted',
  LTLSScheduled: 'LTLSScheduled',
  videoLinksCreated: 'videoLinksCreated',
  playlistLinksCreated: 'playlistLinksCreated',
  videosCreated: 'videosCreated',
  videosDownloaded: 'videosDownloaded',
  '360MediaItemsCreated': '360MediaItemsCreated',
  '360MediaLinksCreated': '360MediaLinksCreated'
};

export const usageTable = [
  {
    key: 'propertyName',
    label: 'Properties',
    customSortable: true
  },
  {
    key: analyticsDataTypes.videosCreated,
    label: 'Videos Created',
    customSortable: true
  },
  {
    key: analyticsDataTypes.videosDownloaded,
    label: 'Videos Downloaded',
    customSortable: true
  },
  {
    key: analyticsDataTypes.videoLinksCreated,
    label: 'Video Links Created',
    customSortable: true
  },
  {
    key: analyticsDataTypes['360MediaItemsCreated'],
    label: '360 Media Items Created',
    customSortable: true
  },
  {
    key: analyticsDataTypes['360MediaLinksCreated'],
    label: '360 Media Links Created',
    customSortable: true
  },
  {
    key: analyticsDataTypes.playlistLinksCreated,
    label: 'Playlist Links Created',
    customSortable: true
  },
  {
    key: analyticsDataTypes.LTLSScheduled,
    label: 'Live Tours/Sessions Scheduled',
    customSortable: true
  },
  {
    key: analyticsDataTypes.LTLSCompleted,
    label: 'Live Tours/Sessions Completed',
    customSortable: true
  }
];

export const leaderboardTable = [
  {
    key: 'propertyName',
    label: 'Properties',
    customSortable: true
  },
  {
    key: analyticsDataTypes.videosCreated,
    label: 'Videos Created',
    customSortable: true
  },
  {
    key: 'videoViews',
    label: 'Video Views',
    customSortable: true
  },
  {
    key: 'LTLSCount',
    label: 'Live Tours & Sessions',
    customSortable: true
  },
  {
    key: 'prospectEngaged',
    label: 'Prospect Engaged',
    customSortable: true
  },
  {
    key: 'createUniqueLinks',
    label: 'Links Created',
    customSortable: true
  }
];

export const insightsTable = [
  {
    key: 'propertyName',
    label: 'Properties',
    customSortable: true
  },
  {
    key: 'L2A',
    label: 'Lead to Application (L2A)',
    customSortable: true
  },
  {
    key: 'A2L',
    label: 'Application to Lease (A2L)',
    customSortable: true
  },
  {
    key: 'avgRealyncCycleTime',
    label: 'Average Realync Prospect Cycle Time (Days)',
    customSortable: true
  }
];

export const smallMostActiveProspectsTableColumns = [
  {
    key: 'prospect',
    label: 'Prospects',
    ignoreSort: true
  },
  {
    key: 'videosViewed',
    label: 'Videos Viewed'
  },
  {
    key: 'LTLSAttended',
    label: 'LT & LS Attended'
  },
  {
    key: 'lastActivity',
    label: 'Last Activity'
  }
];

export const mostActiveProspectsTable = smallMostActiveProspectsTableColumns.map((el) => ({
  ...el,
  ...(!el.ignoreSort && {
    customSortable: true
  })
}));

export const dataTypesKeys = {
  'Live Tours/Sessions completed': {
    key: analyticsDataTypes.LTLSCompleted,
    color: '#d56db4',
    backgroundColor: '#ffe3f6'
  },
  'Videos downloaded': {
    key: analyticsDataTypes.videosDownloaded,
    color: '#f6cf5a',
    backgroundColor: '#fffbed'
  },
  'Playlist Links created': {
    key: analyticsDataTypes.playlistLinksCreated,
    color: '#ffa13c',
    backgroundColor: '#ffe9d0'
  },
  'Live Tours/Sessions scheduled': {
    key: analyticsDataTypes.LTLSScheduled,
    color: '#778bf1',
    backgroundColor: '#e3e7ff'
  },
  'Video Links created': {
    key: analyticsDataTypes.videoLinksCreated,
    color: '#7ad3ce',
    backgroundColor: '#e7fffe'
  },
  'Videos created': {
    key: analyticsDataTypes.videosCreated,
    color: '#3db8e3',
    backgroundColor: '#e1f6fd'
  },
  '360 Media Items Created': {
    key: analyticsDataTypes['360MediaItemsCreated'],
    color: '#50c44f',
    backgroundColor: '#c6ff8c1e'
  },
  '360 Media Links Created': {
    key: analyticsDataTypes['360MediaLinksCreated'],
    color: '#fe8484',
    backgroundColor: '#d4562e19'
  }
};

export const liveEventInsightsOptionsAnalytics = [
  {
    label: 'Live Tours & Live Sessions',
    value: 'all'
  },
  {
    label: 'Live Tours',
    value: 'tour'
  },
  {
    label: 'Live Sessions',
    value: 'session'
  }
];

/**
 * Placeholder values for CounterCard.vue
 */
export const dummyCounters = [
  {
    title: 'Videos created',
    count: 0,
    color: dataTypesKeys['Videos created'].color,
    backgroundColor: dataTypesKeys['Videos created'].backgroundColor
  },
  {
    title: 'Videos downloaded',
    count: 0,
    color: dataTypesKeys['Videos downloaded'].color,
    backgroundColor: dataTypesKeys['Videos downloaded'].backgroundColor
  },
  {
    title: 'Video Links created',
    count: 0,
    color: dataTypesKeys['Video Links created'].color,
    backgroundColor: dataTypesKeys['Video Links created'].backgroundColor
  },
  {
    title: 'Playlist Links created',
    count: 0,
    color: dataTypesKeys['Playlist Links created'].color,
    backgroundColor: dataTypesKeys['Playlist Links created'].backgroundColor
  },
  {
    title: 'Live Tours/Sessions scheduled',
    count: 0,
    color: dataTypesKeys['Live Tours/Sessions scheduled'].color,
    backgroundColor: dataTypesKeys['Live Tours/Sessions scheduled'].backgroundColor
  },
  {
    title: 'Live Tours/Sessions completed',
    count: 0,
    color: dataTypesKeys['Live Tours/Sessions completed'].color,
    backgroundColor: dataTypesKeys['Live Tours/Sessions completed'].backgroundColor
  }
];

export const timeChartPalette = ['3db8e3', 'f6cf5a', '7ad3ce', '7ad378', 'fe8484', 'ffa13c', '778bf1', 'd56db4'];

export const defaultSchema = [{
  name: 'Time',
  type: 'date',
  format: '%m/%d/%Y'
}, {
  name: 'Videos created',
  type: 'number'
}, {
  name: 'Videos downloaded',
  type: 'number'
}, {
  name: 'Video Links created',
  type: 'number'
}, {
  name: '360 Media Items Created',
  type: 'number'
}, {
  name: '360 Media Links Created',
  type: 'number'
}, {
  name: 'Playlist Links created',
  type: 'number'
}, {
  name: 'Live Tours/Sessions scheduled',
  type: 'number'
}, {
  name: 'Live Tours/Sessions completed',
  type: 'number'
}];

export const defaultPlots = [
  {
    value: 'Videos created',
    color: dataTypesKeys['Videos created'].color
  },
  {
    value: 'Videos downloaded',
    color: dataTypesKeys['Videos downloaded'].color
  },
  {
    value: 'Video Links created',
    color: dataTypesKeys['Video Links created'].color
  },
  {
    value: '360 Media Items Created',
    color: dataTypesKeys['360 Media Items Created'].color
  },
  {
    value: '360 Media Links Created',
    color: dataTypesKeys['360 Media Links Created'].color
  },
  {
    value: 'Playlist Links created',
    color: dataTypesKeys['Playlist Links created'].color
  },
  {
    value: 'Live Tours/Sessions scheduled',
    color: dataTypesKeys['Live Tours/Sessions scheduled'].color
  },
  {
    value: 'Live Tours/Sessions completed',
    color: dataTypesKeys['Live Tours/Sessions completed'].color
  }
];

/**
 * Date Picker Options for Reports
 */
export const datePickerOptionsReports = [
  {
    title: 'Last Week\'s Data',
    value: 'week'
  },
  {
    title: 'Last Month\'s Data',
    value: 'month'
  },
  {
    title: 'Last 3 Months Data',
    value: 'threeMonths'
  },
  {
    title: 'YTD Data',
    value: 'YTD'
  }
];

/**
 * Date Picker Options for Analytics
 */
export const datePickerOptionsAnalytics = [
  {
    title: 'Last 30 Days Data',
    value: 'thirtyDays'
  },
  {
    title: 'Last 3 Months Data',
    value: 'threeMonths'
  },
  {
    title: 'Last 12 Months Data',
    value: 'year'
  },
  {
    title: 'YTD Data',
    value: 'YTD'
  },
  {
    title: 'All Time Data',
    value: 'allTime'
  }
];

/**
 * Date Picker Options for Videos List
 */
export const datePickerOptionsVideosList = [
  {
    title: 'Last 30 Days',
    value: 'thirtyDays'
  },
  {
    title: 'Last 3 Months',
    value: 'threeMonths'
  },
  {
    title: 'Last 12 Months',
    value: 'year'
  }
];

/**
 * Review Functionality
 */
export const reviewStatuses = {
  reviewed: 'reviewed',
  rejected: 'rejected',
  pending: 'pending',
  requested: 'requested'
};

export const reviewStatusOptions = [
  {
    label: 'Rejected',
    value: reviewStatuses.rejected
  },
  {
    label: 'Ready to Share',
    value: reviewStatuses.reviewed
  },
  {
    label: 'Sent for Review',
    value: reviewStatuses.pending
  }
];

/**
 * Video statuses
 */
export const videoStatusOptions = [
  {
    label: 'Published',
    value: 'published'
  },
  {
    label: 'Draft',
    value: 'unpublished'
  },
  {
    label: 'Processing',
    value: 'processing'
  }
];

/**
 * User constants
 */

// User roles that we use through application
export const userRolesEnum = ['corp', 'manager', 'agent'];

export const userNormalizedRoleNames = {
  corp: 'Corporate User',
  manager: 'Manager',
  agent: 'Agent'
};

/**
 * "Product type" Functionality
 */
export const userProductTypes = {
  basic: 'basic',
  lite: 'lite',
  core: 'core'
};

export const productTypeTooltipText = {
  [userProductTypes.basic]: 'Host video content with general share options.',
  [userProductTypes.lite]: 'Video creation and management with general and unique share options.',
  [userProductTypes.core]: 'Video creation and management with full sharing, integrations, and live features.'
};

export const productTypeLiteFilterKeys = [
  'Live Tours/Sessions scheduled',
  'Live Tours/Sessions completed',
  'liveToursRewatched',
  'liveSessionsRewatched',
  'liveToursAttended',
  'liveToursAttended',
  'liveSessionsAttended',
  'publicToursAttended',
  'publicTourRSVP',
  'publicToursRewatched',
  analyticsDataTypes.LTLSCompleted,
  analyticsDataTypes.LTLSScheduled,
  'LTLSAttended',
  'lTLSAttendees',
  'liveTours',
  'liveSessions',
  'LTLSCount',
  'lt_show',
  'ls_data',
  'lt_data'
];

export const productTypeBasicFilterKeys = [
  ...productTypeLiteFilterKeys,
  '360 Media Links Created',
  '360MediaLinksCreated',
  'share_banner',
  'links',
  'linkViews',
  'playlistOpens',
  'playlistVideoViews',
  'videoLinkViews',
  'Playlist Links created',
  'Video Links created',
  'videoLinksCreated',
  'playlistLinksCreated',
  'createUniqueLinks',
  'prospectEngaged'
];

/**
 * Twilio publisher/subscriber priorities
 */
export const trackPublishPriority = {
  agent: 'high',
  prospect: 'low'
};

/**
 * We extract video resolutions with these keys, from video object
 */
export const resolutionKeys = [
  'videoUrl',
  'videoUrlFullHd',
  'videoUrlHd'
];

/**
 * We use this resolutionLabels to determine what label should be shown at video player dropdown
 * FullHD and HD keys are case sensitive as we use them to determine the default label
 */
export const resolutionLabels = {
  '4K': '4K (2160p)',
  videoUrlFullHd: 'Full HD (1080p)',
  FullHD: 'Full HD (1080p)', // This is for value that we have at `resolution` field on video object
  videoUrlHd: 'HD (720p)',
  HD: 'HD (720p)' // This is for value that we have at `resolution` field on video object
};

/**
 * Video Recording
 *
 * Dynamically generated attributes are:
 * - h264StreamURL,
 * - managedPropertyId,
 * - mobileVideoImageURL,
 * - runtime,
 * - title,
 * - udid,
 * - videoImageURL,
 * - videoURL
 * - accessibility
 */

export const hostVideoStaticAttributes = {
  source: 'WEB',
  clipsCount: 1, // - Count of clips will be alsways 1 from web
  enableDisclaimer: false, // - Always set to false, no disclaimer for video from web
  enableWatermark: false, // - Always set to false, no watermark for published video from web
  folder: 'none', // - Always set to `none`, video from web should go to root video list by default
  includeBackgroundMusic: false, // - Always set to false from web;
  resolution: 'HD' // - Always set resolution to HD
};

/**
 * Video Clip
 *
 * Dynamically generated attributes are:
 * - duration
 * - finishCaption
 * - fontCaption
 * - h264StreamURL
 * - mobileVideoImageURL
 * - originalVideoName
 * - udid (new udid for clip)
 * - hostVideoUDID
 * - videoURL
 */
export const videoClipStaticAttributes = {
  angleOfCaption: 0, // - Always set to 0
  colorCaption: '1 1 1 1', // - Always set to 1 1 1 1
  editableClip: true, // - Always set to true
  fontSize: 16, // - Always send number 16
  position: 0, // - Always send 0 integer
  startCaption: '0.00000009', // - Always send string 0.0
  title: '', // - Always send empty string
  transformed: '[1, 0, 0, 1, 0, 0]', // - Always send - "[1, 0, 0, 1, 0, 0]"
  widthCoordinate: 2.9296875, // - Always send number 2.9296875
  heightCoordinate: 11.10197353363037, // - Always send number - 11.10197353363037
  xCoordinate: 0.4986666738986969, // - Always send number 0.4986666738986969
  yCoordinate: 0.1159999966621399 // - Always send number 0.1159999966621399
};

/**
 * Video Segment
 *
 * Dynamically generated attributes are:
 * - duration
 * - hostVideoId
 * - clipsUdids
 * - propertyId
 */
export const segmentStaticAttributes = {
  customSegment: false, // - Always set to false
  position: 1, // - Always send 1 integer
  startTime: '0', // - Always send 0 string
  name: '' // - Always send '' empty string
};

/**
 * Video Types
 */

export const videoTypes = [
  'Unit',
  'Amenities',
  'Area/Neighborhood',
  'Maintenance',
  'Resident Event',
  'Internal',
  'Social Media',
  'Other'
];

/**
 *  Video list sort select options
 */

export const videoSortOptionsProperties = [
  {
    label: 'Date Created - New to Old',
    value: 'createdAt_desc'
  },
  {
    label: 'Date Created - Old to New',
    value: 'createdAt_asc'
  },
  {
    label: 'Title - A to Z',
    value: 'title_asc'
  },
  {
    label: 'Title - Z to A',
    value: 'title_desc'
  }
];

export const videoSortOptionsVideos = [
  {
    label: 'Date Updated - New to Old',
    value: 'updatedAt_desc'
  },
  {
    label: 'Date Updated - Old to New',
    value: 'updatedAt_asc'
  },
  {
    label: 'Title - A to Z',
    value: 'title_asc'
  },
  {
    label: 'Title - Z to A',
    value: 'title_desc'
  }
];

/**
 *  Folders list sort select options
 */

export const folderSortOptions = [
  {
    label: 'Title - A to Z',
    value: 'title_asc'
  },
  {
    label: 'Title - Z to A',
    value: 'title_desc'
  },
  {
    label: 'Date Created - New to Old',
    value: 'createdAt_desc'
  },
  {
    label: 'Date Created - Old to New',
    value: 'createdAt_asc'
  }
];

/**
 *  Properties list sort select options
 */

export const propertiesSortOptions = [
  {
    label: 'Name A-Z',
    value: 'name_asc'
  },
  {
    label: 'Name Z-A',
    value: 'name_desc'
  },
  {
    label: 'Videos Count: High to Low',
    value: 'videosCount_desc'
  },
  {
    label: 'Videos Count: Low to High',
    value: 'videosCount_asc'
  },
  {
    label: '360 Count: High to Low',
    value: 'videos360Count_desc'
  },
  {
    label: '360 Count: Low to High',
    value: 'videos360Count_asc'
  },
  {
    label: 'Playlists Count: High to Low',
    value: 'playlistsCount_desc'
  },
  {
    label: 'Playlists Count: Low to High',
    value: 'playlistsCount_asc'
  },
  {
    label: 'Journeys Count: High to Low',
    value: 'journeysCount_desc'
  },
  {
    label: 'Journeys Count: Low to High',
    value: 'journeysCount_asc'
  }
];

/**
 * Task/Recommendation types
 */

export const recommendationTypes = {
  createVideo: 'create_video',
  createVideoInAWhile: 'create_video_in_a_while',
  assignVideoType: 'assign_video_type',
  shareVideo: 'share_video',
  createIntegrationLink: 'create_integration_link',
  createSocialMediaLink: 'create_social_media_link',
  createEmbedCode: 'create_embed_code',
  playlist: 'playlist',
  liveTour: 'live_tour',
  liveSession: 'live_session',
  approvalsPerformed: 'approvals_performed',
  approvalsPerformedInAWhile: 'approvals_performed_in_a_while',
  multiShareVideo: 'multi_share_video',
  shareEmbedCodeLink: 'share_embed_code_link'
};

export const taskTypes = {
  createVideo: 'create_video',
  createPlaylist: 'create_playlist',
  scheduleTour: 'schedule_live_tour',
  scheduleSession: 'schedule_live_session',
  shareVideo: 'share_video',
  liveTourFollowUp: 'live_tour_follow_up',
  liveSessionFollowUp: 'live_session_follow_up',
  other: 'other'
};

export const taskTypeByRecommendationType = {
  create_video: taskTypes.createVideo,
  create_video_in_a_while: taskTypes.createVideo,
  assign_video_type: taskTypes.createVideo,
  multi_share_video: taskTypes.shareVideo,
  live_tour: taskTypes.scheduleTour,
  live_session: taskTypes.scheduleSession,
  playlist: taskTypes.createPlaylist,
  create_embed_code: taskTypes.other,
  share_video: taskTypes.other,
  create_integration_link: taskTypes.other,
  create_social_media_link: taskTypes.other,
  share_embed_code_link: taskTypes.other,
  approvals_performed: taskTypes.other,
  approvals_performed_in_a_while: taskTypes.other
};

/**
 * Journey (VideAsk)
 */

export const videoAskOrganizationId = '9413dd51-cad6-41fb-adc1-f233d0361332';
export const videoAskOrganizationIdStage = '76bc9836-b196-49a2-87cf-d57411047593';

export const constantStepsUdids = {
  trailhead: 'static-ea423416-ade9-48b0-81af-125d2dd121fc',
  wrapUp: 'static-e53f72aa-50d3-4fdf-91bc-5e16fe6865e7',
  otherVideos: 'static-99be25f7-f4b0-45e9-b3e2-50eb5022b522',
  endTour: 'static-c3cb2600-1e3b-405c-a807-dcff162a0abf'
};

export const journeyCustomShareUrl = 'https://journeys.realync.com/';

/**
 * Activity
 */

export const prospectSummaryData = {
  videoShared: {
    backgroundColor: '#E7FFFE',
    color: '#7AD3CE',
    label: 'Video Links Created'
  },
  playlistShared: {
    backgroundColor: '#FFE9D0',
    color: '#FFA13C',
    label: 'Playlist Links Created'
  },
  totalVideoViews: {
    backgroundColor: '#E1F6FD',
    color: '#3DB8E3',
    label: 'Total Video Views'
  },
  totalToursAndSessionScheduled: {
    backgroundColor: '#E3E7FF',
    color: '#778BF1',
    label: 'Live Tours/Sessions Scheduled'
  },
  totalToursAndSessionCompleted: {
    backgroundColor: '#FFE3F6',
    color: '#D56DB4',
    label: 'Live Tours/Sessions Completed'
  },
  totalToursAndSessionViews: {
    backgroundColor: '#FFFBED',
    color: '#F6CF5A',
    label: 'Live Tours/Sessions Views'
  }
};

/**
 * Prospect Timeline item types
 */

export const prospectTimelineTypes = {
  video: [
    'videoShared', 'videoWatched', 'videoSharedEntrata'
  ],
  playlist: [
    'playlistShared', 'playlistOpened', 'playlistVideoWatched'
  ],
  session: [
    'liveSessionCompleted', 'liveSessionWatched'
  ],
  tour: [
    'tourCompleted', 'tourWatched', 'tourRegistered'
  ]
};

/**
 * Video Playback speed
 */

export const videoPlaybackRates = [0.25, 0.5, 1, 1.25, 1.5, 1.75];

/**
 * Thinkific secret for building SSO JWT
 * Link can be found under Help -> Learning Academy
 */

export const thinkificSSOsecret = 'aa24dda5dbf522e6dd46283216d69b29';

/**
 * ChurnZero analytics tool keys
 */

export const productionSecretKey = '1!vd2MO6yzEWltQ3LTTdhFatO0hnrykxPLHvnedRsvMEUt9DD';
export const developSecretKey = '1!gc9TeD5ITKn4C6w4KJc3xgDJtjlNXGx4lmqcA88TIVkt9DB';
export const apiClientUrl = 'https://eu1analytics.churnzero.net/churnzero.js';

/**
 * Microsoft Clarity
 */

export const microsoftClarityId = 'kr8egev252';

/**
 * Intercom secret ids
 */

export const productionIntercomSecretId = 'u8x6ua8f';
export const developIntercomSecretId = 'x68aza7w';

/**
 * Share Modal components map
 */
export const shareModalComponentsIds = {
  generalShare: 'GeneralShareTab',
  prospectLinks: 'ProspectLinksTab',
  embedCode: 'EmbedCodeTabs',
  integrations: 'Integrations',
  qrCode: 'QrCodeTabs',
  socialMedia: 'SocialMediaLinks'
};

/**
 * Modal keys for opening from $route.query params
 */
export const modalKeys = {
  shareModal: 'share-modal',
  photoGalleryModal: 'photo-gallery-modal',
  recordVideoModal: ' record-video-modal',
  playlistModal: 'create-playlist'
};

/**
 * Notification types
 */
export const notificationTypes = {
  emailVideoWatched: 'emailVideoWatched',
  emailLiveTourAction: 'emailLiveTourAction',
  emailLiveSessionAction: 'emailLiveSessionAction',
  emailLiveSessionWatched: 'emailLiveSessionWatched',
  inAppLiveSessionWatched: 'inAppLiveSessionWatched',
  emailLiveTourWatched: 'emailLiveTourWatched',
  requestForReviewEmail: 'requestForReviewEmail',
  inAppLiveTourWatched: 'inAppLiveTourWatched',
  emailPastLiveTourOrSession: 'emailPastLiveTourOrSession',
  emailVideoPublished: 'emailVideoPublished',
  taskEmailSummaryNotification: 'taskEmailSummaryNotification',
  inAppVideoWatched: 'inAppVideoWatched',
  inAppLiveTourAction: 'inAppLiveTourAction',
  inAppLiveSessionAction: 'inAppLiveSessionAction',
  taskPushNotification: 'taskPushNotification',
  yardiVideoViewed: 'yardiVideoViewed',
  captionGeneratedEmail: 'captionGeneratedEmail',
  inAppCaptionGeneratedEmail: 'inAppCaptionGeneratedEmail',
  inAppPhotoGalleryGenerated: 'inAppPhotoGalleryGenerated',
  inAppLiveInsightsGenerated: 'inAppLiveInsightsGenerated',
  emailLiveInsightsGenerated: 'emailLiveInsightsGenerated'
};

/**
 * Widget - Top Three Properties chart colors
 */
export const topThreePropertiesColors = {
  0: '#83d4f1',
  1: '#2ea0d4',
  2: '#efb661'
};
